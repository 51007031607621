<template>
  <v-dialog v-model="dialog" max-width="650">
    <template v-slot:activator="{ on, attrs }">
      <v-card v-bind="attrs" v-on="on" color="green lighten-2" class="white--text my-auto" width="500" height="112">
        <v-card-title class="py-auto px-8 subtitle-2 my-auto">
          Conheça todas as possíveis etapas do
           Informe & Transforme - fluxo
        </v-card-title>
      </v-card>
    </template>
    <v-card>
      <v-card-title>
        Possibilidades de etapas.
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-timeline :loading="loading" align-top dense>
          <v-timeline-item small v-for="i in resultados" :key="`${i.id}m`"
            :color="!i.resultado.macro_text ? i.resultado.cor : 'blue'" right>
            <v-chip label outlined class="mb-1">
              {{ i.resultado.macro_text }} {{ i.resultado.text }}
            </v-chip>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
//import { mapState } from 'vuex'
export default {
  name: "Fluxo",
  data: () => ({
    loading: false,
    dialog: false,
    resultados: [
      {
        "id": 2,
        "resultado": {
          "id": 7,
          "update_url": "/informe/resultado/7/",
          "macro_text": null,
          "created_at": "2021-04-06T16:28:14.661930-03:00",
          "updated_at": "2021-05-04T15:17:55.332972-03:00",
          "created_by": null,
          "updated_by": "831.417.153-01",
          "informe": "SUGESTÃO",
          "text": "Enviada com sucesso pelo colaborador",
          "prazo": 10,
          "cor": "grey",
          "ordem": 1,
          "ativa": true,
          "macro": null
        },
        "created_at": "2021-05-10T13:47:23.428923-03:00",
        "updated_at": "2021-05-10T13:47:23.428923-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      },
      {
        "id": 3,
        "resultado": {
          "id": 6,
          "update_url": "/informe/resultado/6/",
          "macro_text": null,
          "created_at": "2021-04-06T15:39:33.254024-03:00",
          "updated_at": "2021-04-10T09:14:55.086286-03:00",
          "created_by": null,
          "updated_by": "831.417.153-01",
          "informe": "",
          "text": "Enviado para o avaliador",
          "prazo": 10,
          "cor": "orange",
          "ordem": 2,
          "ativa": true,
          "macro": null
        },
        "created_at": "2021-05-10T14:10:59.335151-03:00",
        "updated_at": "2021-05-10T14:10:59.335151-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      },
      {
        "id": 4,
        "resultado": {
          "id": 8,
          "update_url": "/informe/resultado/8/",
          "macro_text": null,
          "created_at": "2021-04-15T10:23:03.780742-03:00",
          "updated_at": "2021-04-20T12:44:17.156092-03:00",
          "created_by": "831.417.153-01",
          "updated_by": "831.417.153-01",
          "informe": "",
          "text": "Devolvido para o administrador",
          "prazo": 10,
          "cor": "purple",
          "ordem": 4,
          "ativa": true,
          "macro": null
        },
        "created_at": "2021-05-10T14:11:11.198082-03:00",
        "updated_at": "2021-05-10T14:11:11.199039-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      },
      {
        "id": 5,
        "resultado": {
          "id": 9,
          "update_url": "/informe/resultado/9/",
          "macro_text": null,
          "created_at": "2021-04-20T12:53:42.876090-03:00",
          "updated_at": "2021-04-20T12:54:20.133689-03:00",
          "created_by": "831.417.153-01",
          "updated_by": "831.417.153-01",
          "informe": "",
          "text": "Devolvido para o usuário",
          "prazo": 10,
          "cor": "blue",
          "ordem": 5,
          "ativa": true,
          "macro": null
        },
        "created_at": "2021-05-10T14:11:27.147987-03:00",
        "updated_at": "2021-05-10T14:11:27.147987-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      },
      {
        "id": 6,
        "resultado": {
          "id": 4,
          "update_url": "/informe/resultado/4/",
          "macro_text": "Devolvido para o usuário",
          "created_at": "2021-04-06T14:44:42.389942-03:00",
          "updated_at": "2021-05-10T11:27:26.992339-03:00",
          "created_by": null,
          "updated_by": "831.417.153-01",
          "informe": "SUGESTÃO",
          "text": "Aprovado",
          "prazo": 10,
          "cor": "green",
          "ordem": 6,
          "ativa": true,
          "macro": 9
        },
        "created_at": "2021-05-10T14:11:35.538482-03:00",
        "updated_at": "2021-05-10T14:11:35.538482-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      },
      {
        "id": 7,
        "resultado": {
          "id": 3,
          "update_url": "/informe/resultado/3/",
          "macro_text": "Devolvido para o usuário",
          "created_at": "2021-04-03T09:08:55.676000-03:00",
          "updated_at": "2021-05-04T17:22:05.162124-03:00",
          "created_by": null,
          "updated_by": "831.417.153-01",
          "informe": "SUGESTÃO",
          "text": "Resultado final - Foi para o banco de sugestões",
          "prazo": 10,
          "cor": "blue",
          "ordem": 7,
          "ativa": true,
          "macro": 9
        },
        "created_at": "2021-05-10T14:11:43.535759-03:00",
        "updated_at": "2021-05-10T14:11:43.535759-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      },
      {
        "id": 8,
        "resultado": {
          "id": 2,
          "update_url": "/informe/resultado/2/",
          "macro_text": "Devolvido para o usuário",
          "created_at": "2021-04-03T09:08:44.327000-03:00",
          "updated_at": "2021-05-10T11:27:44.845557-03:00",
          "created_by": null,
          "updated_by": "831.417.153-01",
          "informe": "SUGESTÃO",
          "text": "Em implementacão",
          "prazo": 10,
          "cor": "green",
          "ordem": 8,
          "ativa": true,
          "macro": 9
        },
        "created_at": "2021-05-10T14:11:46.929356-03:00",
        "updated_at": "2021-05-10T14:11:46.929356-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      },
      {
        "id": 9,
        "resultado": {
          "id": 1,
          "update_url": "/informe/resultado/1/",
          "macro_text": "Devolvido para o usuário",
          "created_at": "2021-04-03T09:08:34.074000-03:00",
          "updated_at": "2021-05-04T17:22:24.077138-03:00",
          "created_by": null,
          "updated_by": "831.417.153-01",
          "informe": "SUGESTÃO",
          "text": "Resultado final -  Não aprovado",
          "prazo": 10,
          "cor": "grey",
          "ordem": 9,
          "ativa": true,
          "macro": 9
        },
        "created_at": "2021-05-10T14:11:50.180008-03:00",
        "updated_at": "2021-05-10T14:11:50.180008-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      },
      {
        "id": 10,
        "resultado": {
          "id": 12,
          "update_url": "/informe/resultado/12/",
          "macro_text": "Devolvido para o usuário",
          "created_at": "2021-05-10T11:28:16.089523-03:00",
          "updated_at": "2021-05-10T11:28:35.941580-03:00",
          "created_by": "831.417.153-01",
          "updated_by": "831.417.153-01",
          "informe": "SUGESTÃO",
          "text": "Resultado final - implementado",
          "prazo": 10,
          "cor": "blue",
          "ordem": 10,
          "ativa": true,
          "macro": 9
        },
        "created_at": "2021-05-10T14:11:53.080004-03:00",
        "updated_at": "2021-05-10T14:11:53.080004-03:00",
        "created_by": "831.417.153-01",
        "updated_by": null,
        "objeto": "SUGESTAO",
        "ordem": 1,
        "descricao": ""
      }
    ]
  }),
  computed: {
    //...mapState(['resultados','loading'])
  },
  methods: {
    /*lista(){
      
      this.$store.dispatch('resultados',{
          method: 'get',
          url: 'informe/workflow/?app=SUGESTAO',
          instancia:'resultados'
        })
      console.log(['res',this.resultados])
      this.$store.dispatch('btnLoading',false)
    }*/
  }

}
</script>